import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserData } from "./rdxFunction";

export default function Approvals({setModalVisible}){

    const isDarkMode = useSelector(state=>state.isDarkMode)
    const userData = useSelector(state=>state.userData.userData)

    const dispatch = useDispatch()

    const approve = (approveId)=>{
        fetch("/approve", {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                approveId : approveId
            })
        })
        .then(data=>data.text())
        .then(data=>{
            if(data.includes("DONE")){
                const newUserData = {...userData}
                newUserData.approvals = newUserData.approvals.filter(i=>!(i._id == approveId))
                dispatch(changeUserData(newUserData))
                setTimeout(()=>{setModalVisible(false)}, 1000)
            }
        })
    }

    return(
        <div className={`approvals ${isDarkMode ? "dark":"light"}`}>
            <h1>Demandes d'approbation</h1>
            {
                userData.approvals.slice().reverse().map(item=>{
                    return(
                        <div className="approvalItem">
                            <p>{item.byName}</p>
                            <p>{item.byEmail}</p>
                            <p>{item.byDescription}</p>
                            <button className="approve" onClick={()=>{approve(item._id)}}>Approuver</button>
                        </div>

                    )
                })
            }
        </div>
    )
}