import { useRef, useState, React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addYearToSave, changeUserData, changeXmlHeader, deleteAllChangedYears } from "./rdxFunction";
import XMLGenerator from "./xmlGenerator";
import XmlHandler from "./xmlHandler";

export default function Editor(){

    let yearSavingTimer = useRef(null)

    const updateXmlData = async ()=>{
        console.error("updateXmlData")
        dispatch(changeXmlHeader(userData.xml.substring(0, userData.xml.indexOf("<div type=\"livre\""))))
        const xml = new XmlHandler(userData.xml)
        await xml.getTexts()
        const newUserData = {...userData}
        newUserData.xml = await xml.getSegments()
        dispatch(changeUserData(newUserData))
        const years = await xml.beautifyAndSortYears()
        setYears(years)
        //updateInputValuesOnYearChange(yearIndex)
        setTimeout(()=>{
            setDisplay(true)
        },2000) 

        /* const test = new XmlHandler(userData.xml)
        test.generateXML(userData.emptyXML) */
    }



    const convertNumberToYear = (year)=>{
            return `${year * (year < 0 ? -1 : 1)} ${year < 0 ? "av." : "ap."} JC`
    }

    const localSave = async ()=>{
        if(changesMade){
            dispatch(addYearToSave({key:convertNumberToYear(years[yearIndex]), value:inputValues}))
        }

        const tmp = {...dataToSave}

        tmp[convertNumberToYear(years[yearIndex])] = inputValues

        return tmp
    }

    const saveKey = (e)=>{
        if((e.ctrlKey || e.metaKey) && e.key=="s"){
            e.preventDefault()
            save()
        }
    }

    const save= async ()=>{
        const dataToSave = await localSave()
        fetch("/save", {
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({dataToSave})
        })
        .then(data=>data.text())
        .then(data=>{
            if(data.includes("DONE")){
                savedLabelRef.current.classList.remove("savedLabelWithAnim")
                savedLabelRef.current.offsetWidth;
                savedLabelRef.current.classList.add("savedLabelWithAnim")
            }
            else{
                alert(data)
            }
        })
        setChangesMade(false)
    }

    const askApproval = ()=>{
        fetch("/askApproval")
        .then(data=>data.text())
        .then(data=>alert(data))
    }
    
    const sendCurrentYearIndex = (newYearIndex)=>{
        fetch("/changecurrentyearindex",{
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                currentYearIndex:newYearIndex
            })
        })
    }

    const nextYear = async ()=>{
        await localSave()
        const newYearIndex = (yearIndex < years.length -1) ? yearIndex +1 : 0
        setYearIndex(newYearIndex)
        if(yearSavingTimer.current) clearTimeout(yearSavingTimer.current)
        yearSavingTimer.current = setTimeout(()=>{
            sendCurrentYearIndex(newYearIndex)
        }, 2000)
        setChangesMade(false)
    }

    const previousYear = async ()=>{
        await localSave()
        const newYearIndex = (yearIndex > 0) ? yearIndex - 1 : years.length - 1
        setYearIndex(newYearIndex)
        if(yearSavingTimer.current) clearTimeout(yearSavingTimer.current)
        yearSavingTimer.current = setTimeout(()=>{
            sendCurrentYearIndex(newYearIndex)
        }, 2000)
        setChangesMade(false)
    }

    const changeInputValues = (e, index)=>{
        const tmp = inputValues.slice();
        let modifiedText = e.target.value.replaceAll("ae", "æ")
        modifiedText = modifiedText.replaceAll("Ae","Æ")
        tmp[index] = modifiedText
        setInputValues(tmp)
        setChangesMade(true)
    }

    const updateInputValues = (xmlData, yearsArray)=>{
        const emptyXMLData = userData.emptyXML
        const yearsList = yearsArray ?? years
        const xml = xmlData ?? userData.xml

        let defaultInputValues = [];
        if(dataToSave[convertNumberToYear(yearsList[yearIndex])]){

            defaultInputValues = dataToSave[convertNumberToYear(yearsList[yearIndex])]
        }
        else if(emptyXMLData && emptyXMLData.find(item=>item.year == convertNumberToYear(yearsList[yearIndex]))){

            const currentYearData = emptyXMLData.find(item=>item.year == convertNumberToYear(yearsList[yearIndex]))
            defaultInputValues = currentYearData.texts
        }
        else if(xml && xml.length == numberOfEditors){
            for(let i=0; i<numberOfEditors;i++){

                let text = "";
                const current = xml[i][convertNumberToYear(yearsList[yearIndex])];
                if(current && current.length > 1){
                    current.forEach((i, index)=>{
                        if(index != current.length -1 ) {
                            text += "\n\n##\n\n"
                        }
                    })
                }
                defaultInputValues.push(text)
            } 
        }
        else{
            defaultInputValues = Array(numberOfEditors).fill("")
        }
        setInputValues(defaultInputValues)
    }

    const textsinfos = [
        "MS A : Winchester Chronicle (Cambridge, Corpus Christi College, MS 173)",
        "MS B : Abingdon Chronicle I (Londres, British Library, Cotton Tiberius A.vi)",
        "MS C : Abingdon Chronicle II (Londres, British Library, Cotton Tiberius B.i)",
        "MS D : Worcester Chronicle (Londres, British Library, Cotton Tiberius B.iv)",
        "MS E : Peterborough Chronicle (Oxford, Bodleian, Laud, misc 636)"
    ]

    const userData = useSelector(state=>state.userData.userData)
    const isDarkMode = useSelector(state=>state.isDarkMode)
    const dataToSave = useSelector(state=>state.dataToSave)
    const dispatch = useDispatch()

    const numberOfEditors = 5; 

    const refsOriginal = [...Array(numberOfEditors)].map(i=>{return new useRef()})
    const refsEdit = [...Array(numberOfEditors)].map(i=>{return new useRef()})
    const savedLabelRef = new useRef(null)

    const buttonRef = new useRef(null)



    const [display, setDisplay] = useState(false)
    const [years, setYears] = useState([])
    const [yearIndex, setYearIndex] = useState(userData.currentYearIndex ?? 0)
    const [changesMade, setChangesMade] = useState(false)



    const [inputValues, setInputValues] = useState(Array(numberOfEditors).fill(""))

    // Load the XML data each time page is reloaded
    useEffect(()=>{
        updateXmlData()
        const interval = setInterval(()=>{
            console.error("interval")
            buttonRef.current.click()
        }, 30000)


        return ()=>{
            clearInterval(interval)
        }

        

    }, [])

    // Update default input values each time year index is changed
    useEffect(()=>{
        updateInputValues()
    }, [yearIndex, userData.emptyXML, userData.xml, years])


    if(display){
        
        return(
            <div className={`editorContainer ${isDarkMode ? "dark":"light"}`}>
                <p className="savedLabel" ref={savedLabelRef}>Enregistré</p>
                <div className="editor">
                    <div  className={`textsInformations ${isDarkMode ? "dark":"light"}`}>
                        {
                            [...Array(numberOfEditors)].map((value, index)=>{
                                return(
                                    <p className="textsInformationsItem" key={index}>
                                        {textsinfos[index]}
                                    </p>
                                )
                            })
                        }
                    </div>
                    <div  className={`editorOriginal ${isDarkMode ? "dark":"light"}`}>
                        {
                            [...Array(numberOfEditors)].map((value, index)=>{
                                
                                return(
                                    <textarea key={index} 
                                        className="editorItem" 
                                        ref={refsOriginal[index]} 
                                        onScroll={(e)=>{if(refsOriginal[index].current === document.activeElement)refsEdit[index].current.scrollTop = e.target.scrollTop}} 
                                        contentEditable={false}
                                        spellCheck={false}
                                        readOnly={true}
                                        disabled={userData.xml[index][convertNumberToYear(years[yearIndex])] ? false:true}
                                        style={{fontSize:userData.textSize+"px"}}
                                        value={userData.xml[index][convertNumberToYear(years[yearIndex])] ? userData.xml[index][convertNumberToYear(years[yearIndex])].join("\n\n##\n\n") : ""}
                                    >
                                    </textarea>
                                )
                            }) 
                        }
                    </div>
                    <div  className={`editorEdit ${isDarkMode ? "dark":"light"}`}>
                        {
                            [...Array(numberOfEditors)].map((value, index)=>{
                                return(
                                    <textarea key={index} 
                                        className="editorItem" 
                                        ref={refsEdit[index]}
                                        onScroll={(e)=>{if(refsEdit[index].current === document.activeElement)refsOriginal[index].current.scrollTop = e.target.scrollTop}}
                                        spellCheck={false}
                                        onKeyDown={saveKey}
                                        style={{fontSize:userData.textSize+"px"}}
                                        //defaultValue={defaultInputValues[index]}
                                        value={inputValues[index]}
                                        onChange={(e)=>{changeInputValues(e, index)}}
                                        disabled={userData.xml[index][convertNumberToYear(years[yearIndex])] ? false: true}
                                    >
                                    </textarea>
                                )
                            }) 
                        }
                    </div>
                </div>
                <div className="textMenu">
                        <div  className={`textMenuDateHandler ${isDarkMode ? "dark":"light"}`}>
                            <div className="date">
                                <img src={require("./images/calendar.png")} alt="Date"/>

                                

                                <select type="text" value={yearIndex} onChange={(e)=>{setYearIndex(parseInt(e.target.value, 10));sendCurrentYearIndex(parseInt(e.target.value, 10))}}>
                                    {
                                        years.map((year, index)=>{
                                            return <option key={index} value={index}>{convertNumberToYear(year)}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <button onClick={previousYear}>Précédent <img src={require("./images/previous.png")} alt="<-"/></button>
                            <button onClick={nextYear}>Suivant <img src={require("./images/next.png")} alt="->"/></button>
                        </div>
    
                        <div  className={`textMenuSaveHandler ${isDarkMode ? "dark":"light"}`}>
                            <button onClick={save} ref={buttonRef}>
                                Enregistrer
                                <img src={require("./images/save.png")} alt=""/>
                            </button>
                            <button onClick={askApproval}>
                                Demander approbation
                                <img src={require("./images/approve.png")} alt=""/>
                            </button>
                        </div>
                    </div>
            </div>
        )
    }
    else{
        return(
            <div className={`loadingPage ${isDarkMode ? "dark":"light"}`}>
                <p>Chargement des données</p>
                <div className="spinner"></div>
            </div>
        )
    }
}