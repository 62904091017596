
export default class XmlHandler{
    xmlFile = ""
    data
    tmpTexts = []
    parser = new DOMParser()
    texts = []
    years = []
    segments = []
    emptyXmlData = []

    constructor(xmlFile){
        if(xmlFile != null){
            this.xmlFile = xmlFile
            this.data = this.parser.parseFromString(this.xmlFile, "text/html")
        }
    }

    generateXML = async (emptyXML, xmlHeader)=>{

        let finalFileContent = ""
        await this.getSegments()
        await this.beautifyAndSortYears()

        const alphabet = "abcdefghijklmnopqrstuvwxyz"

        this.emptyXmlData = emptyXML


        finalFileContent += xmlHeader


        this.texts.forEach((text, textIndex)=>{

            //Creating Livre section 
            finalFileContent += `\n       <div type="livre" xml:id="ms${alphabet.split("")[textIndex]}.ordo">\n`

            console.error(Object.keys(text).length)
            
            
            Object.keys(text).forEach((year, yearIndex)=>{

                const tmp = this.emptyXmlData.find(y=>y.year === year)

                if(tmp && tmp.texts[textIndex] !== ""){
                    finalFileContent +=
`           <div type="section" xml:id="ms${alphabet.split("")[textIndex]}.ordo.${yearIndex+1}">
                <head xml:id="ms${alphabet.split("")[textIndex]}.ordo.${yearIndex+1}.h1">
                    <supplied>${year}</supplied>
                </head>
                <p xml:id="ms${alphabet.split("")[textIndex]}.ordo.${yearIndex+1}.p1">
                ${
                    `   <seg n="1" xml:id="">
                        ${tmp ? tmp.texts[textIndex] : ""}
                    </seg>`
                }
                </p>
            </div>

`
                }

            })
            // Ending livre section
            finalFileContent += `</div>`
        })

        finalFileContent += `</body>
</text>
</TEI>
`

        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(finalFileContent));
        element.setAttribute('download', "finalXML.xml");

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    getXmlFile = ()=>{
        return this.xmlFile
    }

    setXmlFile = (xmlFile)=>{
        this.xmlFile = xmlFile
    }

    getTexts = async ()=>{
        this.tmpTexts = Array.from(this.data.querySelectorAll('[type="livre"]'))
        this.tmpTexts.forEach(()=>this.texts.push({}))
        console.error(this.texts)
    }
    

    beautifyAndSortYears = async ()=>{
        this.years = [...new Set(this.years)]
        this.years = this.years.filter(value=>!value.includes("Préface") && !value.includes("Preface")).map(i=>{
            const splitted = i.split(" ")
            return parseInt(splitted[0]) * (splitted[1].includes("av") ? -1 : 1)
        }).sort((a,b)=>a-b)
        return this.years
    }

    getSegments = async ()=>{
        const parser = new DOMParser()
        this.tmpTexts.forEach((data, listindex)=>{
            const segList = Array.from(data.querySelectorAll('[type="section"]'))
            segList.forEach((segItem, index)=>{
                const seg = parser.parseFromString(segItem.innerHTML, "text/html")
                const key = seg.querySelector("supplied").textContent
                const values = seg.querySelectorAll("seg")
                let value = ""
                values.forEach(i=>value+=i.textContent)
                if(this.texts[listindex][key]){
                    this.texts[listindex][key].push(value)
                }
                else{
                    this.texts[listindex][key] = [value]
                }
                this.years.push(key)
            })
        })
        return this.texts
    }

}