import { RdxConsts } from "./rdxConsts" 

const defaultUserData = {
    email:"",
    name:"",
    description:"",
    isAdmin:false,
    darkMode:false,
    firstConnection:true,
    textSize:17,
    notes:"",
    xml:[],
    emptyXML:[]
}


export const userData = (state={userData:defaultUserData}, action)=>{
    if(action.type===RdxConsts.changeUserData){
        state = {userData:action.payload}
    }
    return state
}

export const isDarkMode = (state=false, action)=>{
    if(action.type===RdxConsts.changeDarkMode){
        state = action.payload
    }
    return state
}

export const isLoggedIn = (state=false, action)=>{
    if(action.type===RdxConsts.changeLoggedIn){
        state = action.payload
    }
    return state
}

export const dataToSave = (state={}, action)=>{
    if(action.type===RdxConsts.addYearToSave){
        state = {...state, [action.payload.key]:action.payload.value}
    }
    else if(action.type===RdxConsts.deleteAllChangedYears){
        state = {}
    }
    return state
}

export const xmlHeaderForGeneration = (state="", action)=>{
    if(action.type===RdxConsts.changeXmlHeader){
        state=action.payload
    }
    return state
}