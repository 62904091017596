import React, {useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeDarkMode, changeLoggedIn, changeUserData } from "./rdxFunction"

export default function LoginPage(){

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passVisible, setPassVisible] = useState(true)

    const dispatch = useDispatch()
    const isDarkMode = useSelector(state=>state.isDarkMode)

    const login = ()=>{
        if(passVisible){
            fetch("/login", {
                method:"POST",
                headers:{"content-type":"application/json"},
                body:JSON.stringify({
                    email,
                    password
                })
            })
            .then(data=>data.text())
            .then(data=>{
                if(data.includes("{")){
                    dispatch(changeUserData(JSON.parse(data)))
                    dispatch(changeDarkMode(JSON.parse(data).darkMode))
                    dispatch(changeLoggedIn(true))
                }
                else{
                    alert(data)
                }
            })
        }
    }

    const askNewPassword = ()=>{
        fetch("/askNewPassword", {
            method:"POST",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                email
            })
        })
        .then(data=>data.text())
        .then(data=>alert(data))
    }

    return(
        <div className="loginPage">
            
            <div  className={`loginPageLogo ${isDarkMode ? "dark":"light"}`} >
                <h1>CAST</h1>
                <img src={require("./images/logo.png")} alt=""/>
            </div>
            <h2 className={isDarkMode ? "dark":"light"}>Outil collaboratif de traduction de manuscrits.</h2>
            <div  className={`loginContainer ${isDarkMode ? "dark":"light"}`} >
                <label>E-mail</label>
                <input type="text" className="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                <label style={{display : passVisible ? "block" : "none"}}>Mot de passe</label>
                <input 
                    style={{display : passVisible ? "block" : "none"}} 
                    type="password" className="password" 
                    value={password} 
                    onChange={(e)=>{setPassword(e.target.value)}} 
                />
                <button onClick={passVisible ? login : askNewPassword}>{passVisible ? "Connexion":"Demander"}</button>
                <button onClick={()=>{setPassVisible(!passVisible)}}>{passVisible ? "Mot de passe oublié":"Connexion"}</button>
            </div>
        </div>
    )
}